import {extendTheme} from '@chakra-ui/react'

const colors = {
    
}

const fonts = {

}

const config = {
    
}

const customTheme = extendTheme({ colors,fonts,config })
export default customTheme