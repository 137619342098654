import {
  Flex,
  Box,
  Text,
  HStack,
  VStack,
  Grid,
  Image,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
} from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import { MdOutlinePhoneAndroid } from "react-icons/md";
import services from "./lib/services";

function App() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [focused, setFocused] = useState({});

  const animatedContainer = {
    hidden: { opacity: 0 },
    show: { opacity: 1, transition: { staggerChildren: 0.15 } },
  };

  const animatedItem = {
    hidden: { opacity: 0 },
    show: { opacity: 1, transition: { staggerChildren: 0.1 } },
  };

  const animatedText = {
    hidden: { opacity: 0, y: -10 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <Flex
      as="main"
      maxW="100vw"
      minH="100vh"
      direction="column"
      alignItems="center"
      bg="url('./hero.jpg')"
      bgSize="cover"
      color="white"
      bgPosition="center"
      backgroundAttachment="fixed"
    >
      <AnimatePresence>
        {isOpen && (
          <Modal
            isOpen={isOpen}
            onClose={onClose}
            as={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <ModalOverlay
              bgColor="rgba(93,64,50, 0.2)"
              backdropFilter="blur(20px)"
            />
            <ModalContent
              marginX={10}
              marginTop="20vh"
              borderRadius="lg"
              overflow="hidden"
              boxShadow="0px 0px 30px rgba(0,0,0,0.8)"
              bgColor="rgba(93,64,50, 0.6)"
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <HStack
                fontSize="clamp(20px, 2vw, 30px)"
                fontWeight="bold"
                color="black"
                w="100%"
                mixBlendMode="screen"
                textAlign="center"
                bgColor="rgba(215,216,212,0.8)"
                justifyContent="center"
                p={2}
              >
                {focused.icon}
                <Text>{focused.name}</Text>
              </HStack>
              <Text
                p={5}
                color="rgba(215,216,212, 0.8)"
                textShadow="0px 0px 10px rgba(0,0,0,0.8)"
                textAlign="center"
              >
                {focused.desc}
              </Text>
              <Text
                fontSize={15}
                fontWeight="bold"
                color="black"
                w="100%"
                mixBlendMode="screen"
                textAlign="center"
                bgColor="rgba(215,216,212,0.8)"
              >
                {focused.price}
              </Text>
            </ModalContent>
          </Modal>
        )}
      </AnimatePresence>
      <Stack
        as="hero"
        direction={["column", "column", "row", "row"]}
        alignItems="center"
        justifyContent="center"
        w="100%"
        backdropFilter="blur(10px)"
        bgColor="rgba(93,64,50, 0.6)"
        p={10}
        boxShadow="0px 0px 30px rgba(0,0,0,0.8)"
      >
        <Image src="./logo.png" alt="Cathy's Day Spa Logo" maxW="200px" />
        <VStack>
          <Text
            fontSize={[30, 30, 40, 40]}
            fontWeight="bold"
            as="h1"
            textAlign="center"
            color="rgba(215,216,212,0.8)"
          >
            Cathy's Day Spa
          </Text>
          <Box
            as={motion.a}
            href="tel:+15099546824"
            cursor="pointer"
            bgColor="rgba(215,216,212,0.8)"
            border="3px solid transparent"
            whileHover={{
              scale: 1.1,
              backgroundColor: "rgba(215,216,212,0)",
              color: "rgba(215,216,212,0.8)",
              borderColor: "rgba(215,216,212,0.8)",
            }}
            whileTap={{
              scale: 0.9,
              backgroundColor: "rgba(215,216,212,0.8)",
            }}
            borderRadius="lg"
            fontSize={20}
            fontWeight="bold"
            color="black"
            paddingX={5}
            mixBlendMode="screen"
            textAlign="center"
          >
            <HStack
              fontSize={20}
              textDecoration="none"
              justifyContent="center"
              alignItems="center"
            >
              <MdOutlinePhoneAndroid />
              <Text textDecor="none">(509) 954-6824</Text>
            </HStack>
          </Box>
        </VStack>
      </Stack>
      <Grid
        marginTop={5}
        templateColumns={[
          "repeat(1, 1fr)",
          "repeat(1, 1fr)",
          "repeat(2, 1fr)",
          "repeat(2, 1fr)",
          "repeat(4, 1fr)",
        ]}
        gap={5}
        as={motion.article}
        variants={animatedContainer}
        initial="hidden"
        animate="show"
      >
        {services.map((section) => (
          <VStack
            w={300}
            backdropFilter="blur(10px)"
            bgColor="rgba(93,64,50, 0.6)"
            borderRadius="lg"
            boxShadow="0px 0px 30px rgba(0,0,0,0.8)"
            overflow="hidden"
            gap={2}
            paddingBottom={5}
            as={motion.section}
            variants={animatedItem}
          >
            <HStack
              fontSize={25}
              fontWeight="bold"
              color="black"
              bgColor="rgba(215,216,212, 0.8)"
              w="100%"
              mixBlendMode="screen"
              textAlign="center"
              justifyContent="center"
            >
              {section.icon}
              <Text as="h2">{section.service}</Text>
            </HStack>
            {section.list.map((item) => (
              <Text
                as={motion.p}
                variants={animatedText}
                cursor="pointer"
                bgColor="rgba(215,216,212,0.8)"
                border="3px solid transparent"
                whileHover={{
                  scale: 1.1,
                  backgroundColor: "rgba(215,216,212, 0)",
                  color: "rgba(215,216,212, 0.8)",
                  borderColor: "rgba(215,216,212, 0.8)",
                }}
                whileTap={{
                  scale: 0.9,
                  backgroundColor: "rgba(215,216,212, 0.8)",
                }}
                borderRadius="lg"
                fontSize={20}
                fontWeight="bold"
                color="black"
                w="90%"
                mixBlendMode="screen"
                textAlign="center"
                onClick={() => {
                  onOpen();
                  item.icon = section.icon;
                  setFocused(item);
                  console.log(item);
                }}
              >
                {item.name}
              </Text>
            ))}
          </VStack>
        ))}
      </Grid>
      <VStack
        marginY={5}
        as="article"
        textAlign="center"
        alignItems="center"
        w={[300, 300, 615, 615, 1260]}
        backdropFilter="blur(10px)"
        bgColor="rgba(93,64,50, 0.6)"
        color="rgba(215,216,212, 0.8)"
        borderRadius="lg"
        boxShadow="0px 0px 30px rgba(0,0,0,0.8)"
        overflow="hidden"
        fontSize={20}
        fontWeight="bold"
      >
        <Text
          as="h1"
          fontSize={25}
          fontWeight="bold"
          color="black"
          bgColor="rgba(215,216,212, 0.8)"
          w="100%"
          mixBlendMode="screen"
          textAlign="center"
          justifyContent="center"
        >
          By Appointment Only
        </Text>
        <Text
          as="p"
          fontSize={15}
          p={5}
          textShadow="0px 0px 10px rgba(0,0,0,0.8)"
        >
          If you need to cancel your appointment, please give as much notice as
          possible. Less than 24 hours notice of cancellation or any changes to
          your appointment are subject to a 50% charge. Last minute
          cancellations or no shows will be charged 100% of the service amount.
        </Text>
        <VStack
          spacing={0}
          color="black"
          bgColor="rgba(215,216,212, 0.8)"
          w="100%"
          mixBlendMode="screen"
        >
          <Text as="h2">Tuesday - Saturday</Text>
          <Text as="p">9:00am - 5:30pm</Text>
        </VStack>
      </VStack>
    </Flex>
  );
}

export default App;
