import {GiHairStrands, GiFootprint} from 'react-icons/gi'
import {FaHandPaper, FaHandHoldingMedical} from 'react-icons/fa'

const services = [
    {
      service: "Foot Services",
      icon: <GiFootprint/>,
      list: [
        {
          name: "Deluxe Pedicure",
          desc: "This full-service pedicure begins with a foot soak in a jetted tub. Followed by trimming and shaping of the nail, reduction of, then smoothing of callous and exfoliation. Finished with a ten-minute professional foot massage and a polish of your selection.",
          price: "$55.00"
        },
        {
          name: "Pedicure",
          desc: "This full-service pedicure begins with a foot soak in a jetted tub. Followed by trimming and shaping of the nail, smoothing of callous and exfoliation. Finished with a polish of your selection.",
          price: "$45.00"
        },
      ],
    },
    {
      service: "Hand Services",
      icon: <FaHandPaper/>,
      list: [
        {
          name: "Deluxe Manicure",
          desc: "This service begins with shaping of the nail and cuticle clean up. Followed by a five-minute hand massage and lacquer polish of your choice.",
          price: "$30.00"
        },
        {
          name: "Manicure",
          desc: "This full-service manicure begins with shaping of the nail and cuticle clean up. Followed by a lacquer polish of your choice.",
          price: "$25.00"
        },
        {
          name: "Deluxe Gel Polish Manicure",
          desc: "This service begins with the removal of old gel polish with conditioning gel remover and warming mitts. Followed by shaping and smoothing of the nail, cuticle clean up, then fresh gel application.",
          price: "$37.00"
        },
        {
          name: "Gel Polish Manicure",
          desc: "This service begins with shaping of the nail and cuticle clean up. Followed by a gel polish application.",
          price: "$32.00"
        },
      ],
    },
    {
      service: "Addons",
      icon: <FaHandHoldingMedical/>,
      list: [
        {
          name: "Glycolic Application",
          desc: "May be added to pedicure for additional callous reduction.",
          price: "$12.00"
        },
        {
          name: "Epsom Salt Soak",
          desc: "May be added to any pedicure service.",
          price: "$4.00"
        },
        {
          name: "Essential Oil Therapy",
          desc: "May be added to any pedicure service.",
          price: "$4.00"
        },
        {
          name: "Warming Paraffin",
          desc: "May be added to pedicure or manicure for extra moisturizing.",
          price: "$15.00"
        },
        {
          name: "French Polish",
          desc: "May be added to any service that includes polish: lacquer or gel.",
          price: "$5.00"
        },
        {
          name: "Specialty Polish",
          desc: "To include any gel polish requiring additional steps during application (may require additional time).",
          price: "$5.00"
        },
        {
          name: "Polish Design",
          desc: "May be added to manicure or pedicure.",
          price: "$1.00 / Nail"
        },
        {
          name: "Additional Massage Time",
          desc: "May be added to manicure or pedicure.",
          price: "$1.50 / Min"
        },
      ],
    },
    {
      service: "Waxing Services",
      icon: <GiHairStrands/>,
      list: [
        {
          name: "Brow Shaping",
          desc: "Personalized consultation. Followed by a professional shaping of the brow, Once a month follow-up visits to maintain brow shape.",
          price: "$25.00"
        },
        {
          name: "Brow Maintenance",
          desc: "Maintenance of a previous brow shaping generally 4 weeks to keep it touched up.",
          price: "$15.00"
        },
        {
          name: "Upper lip",
          desc: "Hair removal of the upper lip.",
          price: "$15.00"
        },
        {
          name: "Nose",
          desc: "Wax nose hair.",
          price: "$12.00"
        },
        {
          name: "Chin",
          desc: "Wax hair on chin.",
          price: "$15.00"
        },
        {
          name: "Ear",
          desc: "Wax ear hair.",
          price: "$15.00"
        },
        {
          name: "Full Face",
          desc: "Wax hair on all areas of the face.",
          price: "$50.00"
        },
        {
          name: "Under Arms",
          desc: "Hair removal in armpits.",
          price: "$23.00"
        },
        {
          name: "Wax Full Back / Chest",
          desc: "Hair removal of the Back.",
          price: "$50.00"
        },
        {
          name: "Wax Full Leg",
          desc: "Removal of leg hair spanning full leg.",
          price: "$70.00"
        },
        {
          name: "Wax To Knee",
          desc: "Removal of leg hair from foot to knee.",
          price: "$45.00"
        },
      ],
    },
  ];

  export default services